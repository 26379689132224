import * as React from 'react'
// import { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Carousel } from 'react-responsive-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Layout from '../layout'
import IconWhatsapp from '../components/whatsicon'
import Section from '../layout/section'
import Button from '../components/button'
import TextImage from '../components/text_image'
import Tabela from '../images/home/tabela.svg'
import Rectangle2 from '../images/home/retangule.svg'

import Businessman from '../images/home/businessman.svg'
import Calc from '../images/home/calc.svg'
import Orc_anual from '../images/home/orc_anual.svg'
import Business from '../images/home/business.jpg'

import Line from '../images/home/Group51.svg'
import Line2 from '../images/home/Group52.svg'
import Line3 from '../images/home/Group53.svg'
import Line4 from '../images/home/Group54.svg'
import Line5 from '../images/home/Group55.svg'
import Line6 from '../images/home/Group56.svg'
import Line7 from '../images/home/Group57.svg'
import Line8 from '../images/home/Group58.svg'

// import EngineSVG from '../images/home/engine.svg'
// import LousaSVG from '../images/home/lousa.svg'

import SeloImg from '../images/home/selo.png'
import Man from '../images/home/man.svg'
import Car from '../images/home/car.svg'
import Motor from '../images/home/motor.svg'
import Motor2 from '../images/home/motor2.svg'
import Balanca from '../images/home/balanca.svg'
import Martelo from '../images/home/martelo.svg'
import Relatorio from '../images/home/relatorio.svg'
import Ferramentas from '../images/home/ferramentas.svg'
import Barra from '../images/home/barra.svg'
import Group from '../images/Group1.svg'
import Adm from '../images/home/adm.svg'

import ImageBg from '../components/image_bg'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './index.css'

const IndexPage = () => {
  //   const [currentSlide, setCurrentSlide] = useState(0)

  //   const sliderRef = useRef(null)

  // //   const goToFeature = (e) => {
  // //     if (currentSlide !== parseInt(e.currentTarget.dataset.index)) {
  // //       setCurrentSlide(e.currentTarget.dataset.index)
  // //       sliderRef.current.slickGoTo(e.currentTarget.dataset.index)
  // //     }
  // //   }


  return (
    <Layout>

      <Helmet>
        <title>Motorbooks - Início</title>
      </Helmet>
      <Carousel
        interval={10000}
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
        infiniteLoop
        autoPlay
      >
        {/* <div id='slide-1'>
          <div className='left'>
            <h2 className='text'>
              Mais de <span className='bold-text'>130 mil</span> informações técnicas, medidas e especificações de motores!
            </h2>
            <h3 className='blue-text'>
              <span className='bold-text'>5 acessos gratuitos</span> para você conhecer <br />
              o <span className='bold-text'>maior</span> banco de dados em motores!
            </h3>
            <Button text='Adquira já 5 acessos gratuitos!' linkTo='coming_soon' />
            <Button text='Crie sua Conta' linkTo='coming_soon' isDark />
          </div>
          <div className='right'>
            <img src={EngineSVG} alt='Motor' />
          </div>
        </div> */}
        <div id='slide-2'>
          <div className='left'>
            <img src={SeloImg} alt='Selo Motorbooks' />
          </div>
          <div className='right'>
            <h1 className='blue-text'>
              Você sabia que a <span className='bold-text'>ABNT 13032</span> diz ser
              obrigatório a aplicação do Selo de Registro de Temperatura
              nos motores reinstalados nas retificas de motores?
            </h1>
            <Button text='Conhecer' linkTo='/' anchorTo='#product' />
          </div>
        </div>
        {/* <div id='slide-3'>
          <div className='left'>
            <h1 className='text'>
              Aproveite esta <span className='bold-text'>oferta</span> e <br />
              Seja um <span className='bold-text'>assinante</span> MotorBooks!
            </h1>
            <h2 className='blue-text'>
              Pacotes a <span className='bold-text'>partir de R$ 99,00</span>
            </h2>
            <Button text='Criar sua Conta' linkTo='/coming_soon' />
            <Button text='Opções de Pacotes' linkTo='/plans' isDark />
          </div>
          <div className='right'>
            <img src={LousaSVG} alt='Lousa com Motorbooks' />
          </div>
        </div> */}
      </Carousel>

      <IconWhatsapp />

      <div className='lines'>

        <div className='line'>
          <img src={Line} alt='Linhas decorativas' />
        </div>

        <div className='line-right'>
          <img src={Line2} alt='Linhas decorativas' />
        </div>

        <div className='line2'>
          <img src={Line3} alt='Linhas decorativas' />
        </div>

        <div className='line-right2'>
          <img src={Line4} alt='Linhas decorativas' />
        </div>

        <div className='line3'>
          <img src={Line5} alt='Linhas decorativas' />
        </div>

        <div className='line-right3'>
          <img src={Line6} alt='Linhas decorativas' />
        </div>

        <div className='line4'>
          <img src={Line7} alt='Linhas decorativas' />
        </div>

        <div className='line-right4'>
          <img src={Line8} alt='Linhas decorativas' />
        </div>
      </div>

      <Section id='features'>

        <h1 className='blue-text' style={{ paddingBottom: '39px' }}>
          <div id='eng'>
            Departamento de Engenharia
          </div>
          <div className='barra' style={{ height: '4px', paddingBottom: '33px' }}>
            <img src={Barra} alt='Barra de Divisão' />
          </div>
        </h1>

        <TextImage
          text={
            <div>
              <li className='list'>
                Consultoria técnica para casos de quebra de motor com intuito<br />
                de detectar se o real motivo da falha foi
                por mau uso por parte do<br />
                cliente, problema com a peça ou falha no momento da<br />
                montagem. Podendo elaborar
                laudo técnico específico válido<br />
                juridicamente para tal situação;
              </li>
              <li>
                Informações técnicas, informações de usinagem dos motores e<br />
                assessoria para retificadores e seus colaboradores;
              </li>
              <li>
                Avaliação, análises, elaboração de relatórios técnicos em peças<br />
                e componentes internos, agregados e periférico de  motores<br />
                automotivos ciclo Otto / Diesel, linhas leves e pesadas;
              </li> <br />
              <div style={{ textAlign: 'center' }}>
                <Button text='Saiba Mais' linkTo='/' anchorTo='#eng-lab' />
              </div>
            </div>
          }
          subtitle='Nosso departamento é completo, veja tudo que podemos oferecer a sua Retifica de Motores e Oficina Mecânica:'
          image={Motor}
          sizeimage='1'
          alt='LousaSVG'
          position='text-right'
        />

        <TextImage
          text={
            <div id='eng-lab' className='list'>
              <li>
                Elaboração de ensaios metalográficos em escala laboratorial em<br />
                falhas estruturais de peças e componentes fixos e móveis em<br />
                motores a combustão interna.
              </li>
              <li>
                Analise de combustíveis e óleos lubrificantes e resultados<br />
                conclusivos entre o especificado pelo fabricante com o aplicado<br />
                ao motor.
              </li>
              <li>
                Atuação direta junto às retificas de motores, oficinas e centros<br />
                automotivos, em casos de mediação entre fabricante de peças,<br />
                aplicadores e usuários, sempre direcionados através de relatórios<br />
                técnicos e análises elaboradas através
                de ensaios laboratoriais<br />
                certificados.
              </li> <br />
              <div style={{ textAlign: 'center' }}>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o departamento de engenharia da Motorbooks!' />
              </div>
            </div>
          }
          image={Ferramentas}
          sizeimage='2'
          alt='LousaSVG'
          position='text-left' />

        <TextImage
          text={
            <div className='list'>
              <li>
                Análises especificas em casos de ruptura de eixo de virabrequim<br />
                e de comando de válvulas;
                em casos de gripagens pistão/cilindro<br />
                e origem de calços hidráulicos e mecânicos.
              </li>
              <li>
                Elaboração de estudo do desgaste precoce e irregular dos<br />
                componentes do trem de força e partes altas do motor.
              </li>
              <li>
                Análise de bombas e bicos injetores do sistema de alimentação a<br /> diesel.
              </li>
              <li>
                Orientação direta e indiretamente para especificações de<br />
                maquinas de retificas, dispositivos, ferramentas, instrumentos de<br />
                aferição, calibração, lubrificante, óleo hidráulicos, pressão dos<br />
                sistemas acoplantes, elétrico/eletrônico, entre outros, com a<br />
                intenção de ajudar com caminhos alternativos no mercado para a<br />
                melhor solução para o cliente.
              </li> <br />
              <div style={{ textAlign: 'center' }}>
                <Button text='Nossos Produtos' linkTo='/' anchorTo='#product' />
              </div>
            </div>
          }
          image={Motor2}
          sizeimage='3'
          alt='LousaSVG'
          position='text-right' />

      </Section>


      <Section id='features'>

        <div className='barra'>
          <img src={Barra} alt='Barra de Divisão' style={{ paddingTop: '15px', height: '4px', paddingBottom: '10px' }} />
        </div>

        <h1 id='juridico' className='blue-text' style={{ paddingBottom: '39px', paddingTop: '0px' }}>
          Departamento Jurídico
        </h1>

        <TextImage
          text={
            <div style={{ textIndent: '1em' }}>
              <p>
                A Lei Geral de Proteção de Dados (13.709/2018) tem como objetivo proteger os direitos fundamentais
                de liberdade e de privacidade da pessoa, assim como a livre formação da personalidade de cada indivíduo.
                A norma visa regulamentar o controle, o uso e o tratamento dos dados pessoais pelas empresas no Brasil.
              </p>
              <p>
                As empresas precisam se preparar de maneira adequada e precisa para que a sua implantação seja não somente eficaz,
                mas também entendida por todos os envolvidos.
              </p>
              <p>
                Primeiramente é preciso definir um cronograma de trabalho em equipe para tratar a LGPD. O programa deve
                contemplar todas as áreas da organização que tratam dados como TI, RH, Jurídico, Marketing, Financeiro,
                Contabilidade, Operação, entre outros.
              </p>
              <p>
                Importante também organizar um grupo de trabalho multidisciplinar com pessoas dedicadas ao tema em suas áreas
                que serão os LPDs (Líderes da Privacidade de dados locais). Os LPDs são as pessoas que conhecem profundamente os
                processos de negócio que envolvem dados pessoais e dados sensíveis.
              </p>
            </div>

          }
          subtitle='LGPD'
          image={Balanca}
          sizeimage='8'
          alt='LousaSVG'
          position='text-left'
        />

        <div>
          <div className='legal-department' >
            <div>
              <p>
                O conceito de dado pessoal é bastante abrangente, sendo definido como a “informação
                relacionada a pessoa identificada ou identificável”. Isso quer dizer que um dado é
                considerado pessoal quando ele permite a identificação, direta ou indireta, da pessoa
                natural por trás do dado. Como por exemplo: nome, sobrenome, data de nascimento, documentos pessoais
                (como CPF, RG, CNH, carteira de Trabalho, passaporte e título de eleitor), endereço
                residencial ou comercial, telefone, e-mail,
                cookies e endereço de IP.
              </p>
              <p>
                Já os dados sensíveis são os dados que, associados aos dados pessoais, podem gerar constrangimento ao
                titular, são aqueles que se referem à “origem racial ou étnica, convicção religiosa, opinião política,
                filiação a sindicato ou organização de caráter religioso, filosófico ou político, dado referente à saúde ou à
                vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural”. Por seu maior potencial lesivo, o
                tratamento desses dados deve observar regras ainda mais rígidas. Atenção redobrada aos dados pessoais sensíveis,
                pois esses podem expor a organização a sérios riscos se não tratados adequadamente.
              </p>
            </div>

            <div>
              <p>
                De posse dessas informações, já é possível definir a estratégia com a equipe e definir os papeis
                e responsabilidades, bem como o Encarregado de dados (DPO) que será o mediador entre a organização
                e a Autoridade Nacional de Dados e os Titulares dos dados. Não cometa o erro de simplesmente sair
                comprando soluções tecnológicas para fazer proteção sem um sistema de gestão de dados pessoais com
                políticas bem definidas e implantadas.
              </p>
              <p>
                Esse plano de gerenciamento de implantação da LGPD considera 10 tópicos principais. Cada tópico
                sugere processos e procedimentos para uma governança de dados em compliance com a LGPD.
              </p>
              <p>
                No guia Controle de Projetos todos os tópicos estão divididos em tarefas detalhadas que servem de
                base para elaborar, gerenciar, acompanhar e documentar o andamento da implantação da LGPD.
                A guia Checklist LGPD serve como uma nova checagem por artigos da LGPD e se a organização atende
                integralmente a lei no seu contexto de negócio.
              </p>
              <p>
                Na guia Dashboard um painel com indicadores e gráficos oferecem um panorama sobre o andamento da
                implantação para as medidas gerenciais necessárias.
                Essa ferramenta tornará sua jornada mais produtiva e eficiente na implantação da LGPD!
              </p>
            </div>
          </div>

          <div style={{ textAlign: 'center', marginBotton: '1em' }}>
            <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o departamento jurídico da Motorbooks!' />
          </div>
        </div>


        <TextImage
          text={
            <div>
              <div style={{ textIndent: '1em' }}>
                <p>
                  Nosso departamento jurídico prestará assessoria e acompanhamento em casos de conflito
                  com cliente e ou fornecedor.
                </p>
                <p>

                  Sabemos que muitas vezes defeitos em peças acontecem e é preciso a intervenção de um
                  Advogado para acompanhar o caso com o fabricante. Assim como sabemos do quanto é comum
                  as Retíficas/Oficinas receberem pedidos de indenização por serviços considerados não eficazes pelo cliente.
                </p>
                <p>
                  Por isso nosso departamento é e está preparado para atendê-los nessas situações com o
                  intuito de resolver da melhor maneira esse conflito com o cliente.  Neste momento nosso
                  departamento de Engenharia, com anos de experiência no setor, poderá ser solicitado
                  juntamente para que esclarece os fatos de maneira técnica e detalhada através de laudos
                  muito bem elaborados.
                </p>
              </div>

              <div style={{ textAlign: 'center', marginTop: '1em' }}>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o setor automotivo do departamento jurídico da Motorbooks!' />
              </div>
            </div>

          }
          subtitle={
            <p>
              <img src={Car} alt='Icone de um carro' style={{ paddingRight: '1em' }} />
              Setor Automotivo
            </p>
          }
          image={Relatorio}
          sizeimage='4'
          alt='LousaSVG'
          position='text-right'
        />

        <TextImage
          text={
            <div>
              <div style={{ textIndent: '1em' }}>
                <p>
                  Os processos trabalhistas são motivos de muita preocupação por parte das empresas.
                  Com uma consultoria jurídica preventiva e adequada é possível diminuir os riscos de
                  tais processos, além de, ao enfrentá-los, ter muito mais sucesso na ação.
                </p>
                <p>
                  Nossa proposta de trabalho é baseada na prevenção, nos adiantaremos aos possíveis
                  acontecimentos na área trabalhista para evita-los. Cada empresa com suas características
                  e peculiaridades será analisada de forma individual e precisa para elaborarmos o plano
                  de ação a ser trabalhado.
                </p>
                <p>
                  A gestão dos colaboradores, o cumprimento à legislação principalmente após a reforma
                  Trabalhista (Lei nº 13.467 de 2017), as obrigações tributárias, os modelos de contratos,
                  as documentações adequadas e os documentos referentes à segurança do trabalho são alguns
                  dos trabalhos desenvolvidos para auxiliar as empresas a se precaverem judicialmente.
                  Atenderemos todos os requisitos legais de forma a auxiliar na definição dos processos,
                  esclarecer dúvidas, adequar o que for preciso de contrato e dar apoio às rotinas existentes.
                </p>
                <p>
                  Temos um  modelo de planilha para melhor entender a maneira de trabalho!
                </p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '1em' }}>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o setor trabalhista do departamento jurídico da Motorbooks!' />
              </div>
            </div>
          }

          subtitle={
            <p>
              <img src={Man} alt='Icone de um homen' style={{ paddingRight: '1em' }} />
              Trabalhista
            </p>
          }
          image={Martelo}
          sizeimage='6'
          alt='LousaSVG'
          position='text-left'
        />

      </Section>


      <Section id='features'>

        <div className='barra'>
          <img src={Barra} alt='Barra de Divisão' style={{ paddingTop: '45px', height: '4px', paddingBottom: '1px' }} />
        </div>

        <h1 id='administrativo' className='blue-text' style={{ paddingBottom: '39px', paddingTop: '0px' }}>
          Departamento Administrativo
        </h1>

        <div className='administrative_img'>
          <ImageBg size='10' image={Adm} />
        </div>


        <div className='administrative'>

          <div className='administrative-category'>
            <div className='managers'>
              <h2 className='blue-text'>Investimentos</h2>
              <p>
                Traçaremos seu perfil investidor orientando sempre nas tomadas de decisões a fim de que seus sonhos,
                objetivos e metas sejam alcançados. Analisar o perfil de cada um é de extrema importância,
                assim como proteger seus recursos também, por isso, responsabilidade e conhecimento na área são muito importantes.
              </p>
            </div>

            <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre a orientação em investimentos do departamento administrativo da Motorbooks!' />

          </div>

          <div className='administrative-category'>
            <div className='managers'>
              <h2 className='blue-text'>Gestão de custos</h2>
              <p>
                Conhecer seus custos na palma da mão faz com que suas estratégias sejam melhores elaboradas!
                É através da gestão de custos que se conquista uma gestão financeira ideal! Podemos te ajudar
                a apurar custos, ter um centro de custo detalhado, reduzi-los de forma racional e bem elaborada,
                enxergar
                onde há pontos de melhoria, onde há ociosidade, tomar decisões em ralação a estoque, enfim,
                ter um centro de custo na palma da sua mão!
              </p>
            </div>

            <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre a gestão de custos do departamento administrativo da Motorbooks!' />

          </div>
        </div>

        <div className='administrative'>
          <div className='administrative-category'>
            <div className='managers'>
              <h2 className='blue-text'>Gestão financeira</h2>
              <p>
                Um dos pilares mais importantes e mais olhados pelos gestores. Uma boa gestão financeira
                traz a possibilidade de bons investimentos, boas tomadas de decisões quando se realmente
                tem lucratividade na empresa. Buscaremos diagnosticar os problemas, propor as soluções e
                apresentar estratégias e projetos para desenvolver um bom desempenho e melhorar os resultados.
              </p>
            </div>

            <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre a gestão financeira do departamento administrativo da Motorbooks!' />

          </div>
          <div className='administrative-category'>
            <div className='managers'>
              <h2 className='blue-text'>Gestão empresarial</h2>
              <p>
                Queremos ajuda-lo a diagnosticar os problemas e analisar os dados da sua empresa com o
                objetivo de crescer de forma sustentável e alcançar resultados mais satisfatórios. Abrangeremos planejamento,
                estratégia, marketing, finanças e gestão de pessoas.
                Por meio da avaliação e do acompanhamento de indicadores estabelecidos traçaremos a melhor
                forma para que os processos empresariais se tornem mais eficientes através dos recursos
                disponíveis e reduzindo os custos.
              </p>
            </div>

            <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre a gestão empresarial do departamento administrativo da Motorbooks!' />

          </div>
        </div>

      </Section>

      <Section id='presentation'>

        <h1 className='blue-text' style={{ paddingBottom: '39px', paddingTop: '0px' }}>
          Ferramentas
        </h1>

        <div className='ferramentas'>
          <div className='product_manual'>
            <div className='manual'>
              <ImageBg size='10' image={Businessman} />
              <div className='button_product'>
                <p>Custo Venda Peça</p>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o custo venda peça das ferramentas disponibilizadas pela Motorbooks!' />
              </div>
            </div>

            <div className='manual'>
              <ImageBg size='11' image={Calc} />
              <div className='button_product'>
                <p>Ficha Técnica Serviço</p>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o ficha técnica serviço das ferramentas disponibilizadas pela Motorbooks!' />
              </div>
            </div>
          </div>


          <div className='product_manual'>
            <div className='manual'>
              <ImageBg size='10' image={Orc_anual} />
              <div className='button_product'>
                <p>Orçamento Anual</p>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o custo despesa viagem das ferramentas disponibilizadas pela Motorbooks!' />
              </div>
            </div>

            <div className='manual'>
              <ImageBg size='11' image={Business} />
              <div className='button_product'>
                <p>Custo Mão de Obra</p>
                <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre o custo mão de obra das ferramentas disponibilizadas pela Motorbooks!' />
              </div>
            </div>
          </div>
        </div>

      </Section>

      <Section id='presentation' isDark>

        <h1 id='product' className='text'>
          Produtos de Excelência<br />
          um Trabalho de Qualidade
        </h1>

        <div className='selos'>

          <div className='product_selo'>
            <img src={Tabela} alt='Tabela de informações'/>
          </div>

          <div className='text_selo'>

            <h1>Selo de Temperatura</h1>

            <p>
              O Selo de Registro de temperatura MotorBooks é seguro, fabricado nos EUA com o melhor padrão de
              qualidade, inclusive homologado por importantes fabricantes de motores na linha automotiva,
              aeronáutica e náutica, geradores e afins. Possui uma escala que escurecerá e registrará a
              temperatura
              máxima atingida nos mostrando se o motor excedeu o limite devido mau uso ou problemas na sua
              reinstalação ou sistema de refrigeração ineficiente.
            </p>
            <p>
              Proteja-se de possíveis garantias e ações judiciais!
            </p>
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
              <Button text='Entre em Contato' linkTo='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de saber mais sobre os selos comercializados pela Motorbooks!' />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className='contact-us'>
          <div className='conteudo-contato'>
            <div className='left'>
              <img src={Group} alt='Mulher pesquisando' height="350px" />
            </div>
            <div className='right'>
              <h2 className='contact-text'>Não encontrou o que precisava?</h2>
              <h2 className='black-text'>Entre em contato e nossa equipe</h2>
              <h2 className='black-text'>estará pronta para te ajudar!</h2>

              <p className='whatsapp-contact'>
                <a href='https://api.whatsapp.com/send?phone=5511988924847&text=Olá, gostaria de conhecer mais sobre a Motorbooks!' target='_blank' rel='noreferrer'>
                  <span class='fa-stack'>
                    <FontAwesomeIcon icon={faSquare} style={{ color: '#FFFFFF', fontSize: '21.2pt' }} className='fa-stack-1x' />
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366', fontSize: '24pt' }} className='fa-stack-1x' />
                  </span>
                
                Contato via WhatsApp
                </a>
              </p>
            </div>
          </div>
        </div>
      </Section>

    </Layout>
  )
}

export default IndexPage
