import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const IconWhatsapp = () => {
  return (
    <div className='icon-whats'>
      <a href='https://api.whatsapp.com/send?phone=5511988924847'><FontAwesomeIcon icon={faWhatsapp} style={style}  className='fa-stack-1x' /></a>
    </div>
  )
}

const style = {
  color: '#FFFFFF',
  fontSize: '42pt',
  background: '#25D366',
}

export default IconWhatsapp
