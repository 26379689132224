import React from 'react'
import ImageBg from '../components/image_bg'

const TextImage = ({ title, subtitle, text, image, alt, position, sizeimage }) => {

  let component = (
    <></>
  )

  if (position === 'text-right') {
    component = (
      <div className={position}>

        <div className='imgfstyles'>

          <ImageBg size={sizeimage} />
          <div className='imgsstyle'>
            <img src={image} alt={alt}/>
          </div>
        </div>

        <div className='sstyle'>
          <h1 style={hStyle}>{title}</h1>
          <p className='subtitle-text'>{subtitle}</p>
          <div className='text-image' style={{alignItems: 'center'}}>{text}</div>
        </div>

      </div>
    )
  }

  if (position === 'text-left') {
    component = (
      <div className={position}>

        <div className='sstyle'>
          <h1 style={hStyle}>{title}</h1>
          <p className='subtitle-text'>{subtitle}</p>
          <div className='text-image' style={{alignItems: 'center'}}>{text}</div>
        </div>
  
        <div className='imgfstyles'>
          <ImageBg size={sizeimage} />
          <div className='imgsstyle'>
            <img src={image} alt={alt}/>
          </div>
        </div>
      </div>
    )
  }

  return component
}


const hStyle = {
  paddingBottom: '21px',
  color: '#2967B0',
  fontFamily: 'Roboto',
  fontSize: '36px',
  fontWeight: '700px',
  lineHeight: '42px',
  letterSpacing: '0em',
  textAlign: 'justify'
}


export default TextImage
