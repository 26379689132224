import React from 'react'

import Bb1 from '../images/home/bola1.svg'
import Bb2 from '../images/home/bola2.svg'
import Bb3 from '../images/home/bolinha3.svg'
import Bb4 from '../images/home/bolinha4.svg'
import Bb5 from '../images/home/bola5.svg'
import Bb7 from '../images/home/bola7.svg'
import Bb8 from '../images/home/bola8.svg'


const ImageBg = ({size, image}) => {

  let component = (
    <></>
  )

  if (size === '1') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb1} style={{left: '-100px', top:'-50px', position: 'relative'}}/>
        <img id='ball' src={Bb2} style={{left: '265px', top:'300px', position: 'relative'}}/>
      </div>
      )
    }

  if (size === '2') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb3} style={{left: '-70px', top:'0px', position: 'relative'}}/>
        <img id='ball' src={Bb4} style={{left: '350px', top:'330px', position: 'relative'}}/>
      </div>
      )
    }

  if (size === '3') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb5} style={{left: '-100px', top:'10px', position: 'relative'}}/>
        <img id='ball' src={Bb5} style={{left: '280px', top:'320px', position: 'relative'}}/>
      </div>
      )
  }

  if (size === '4') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb5} style={{left: '-100px', top:'10px', position: 'relative'}}/>
        <img id='ball' src={Bb5} style={{left: '300px', top:'320px', position: 'relative'}}/>
      </div>
      )
  }

  if (size === '5') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb5} style={{left: '-100px', top:'10px', position: 'relative'}}/>
        <img id='ball' src={Bb4} style={{left: '300px', top:'330px', position: 'relative'}}/>
      </div>
      )
  }


  if (size === '6') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb7} style={{left: '300px', top:'0px', position: 'relative'}}/>
        <img id='ball' src={Bb4} style={{left: '-185px', top:'320px', position: 'relative'}}/>
      </div>
      )
  }

  if (size === '7') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} width='478px' />
        <img id='ball' src={Bb4} style={{left: '-10px', top:'-20px', position: 'relative'}}/>
        <img id='ball' src={Bb8} style={{left: '400px', top:'360px', position: 'relative'}}/>
      </div>
      )
  }

  if (size === '8') {
    component = (
      <div className='test-imagem' style={{width:'450px', height:'310px'}}>
        <img src={image} />
        <img id='ball' src={Bb5} style={{left: '-100px', top:'10px', position: 'relative'}}/>
        <img id='ball' src={Bb5} style={{left: '270px', top:'300px', position: 'relative'}}/>
      </div>
      )
  }

  if (size === '10') {
    component = (
      <div className='test-imagem' style={{width:'auto'}}>
        <img id='ball' src={Bb5} style={{left: '20px', top:'-260px', position: 'relative', width: '50px'}}/>
        <img id='ball' src={Bb5} style={{left: '300px', top:'30px', position: 'relative', width: '96px'}}/>
        <img src={image} style={{position:'relative', width:'366px', height:'277px', left:'-100px'}} />
      </div>
      )
  }


  if (size === '11') {
    component = (
      <div className='test-imagem' style={{width:'auto'}}>
        <img id='ball' src={Bb5} style={{left: '390px', top:'-260px', position: 'relative', width: '50px'}}/>
        <img id='ball' src={Bb5} style={{left: '-50px', top:'30px', position: 'relative', width: '96px'}}/>
        <img src={image} style={{position:'relative', width:'366px', height:'277px', left:'-100px'}} />
      </div>
      )
  }

  return component
}


export default ImageBg
